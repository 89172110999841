import React from "react";
import { ChakraProvider, Box, Text } from "@chakra-ui/react";
import theme from "./const/theme";
import AppProvider from "./AppProvider";
import Navbar from "./components/nav/Navbar";
import DevBy from "./components/DevBy";
import Cards from "./components/Cards";
import {
  Routes,
  Route,
} from "react-router-dom";
import BackendServicePage from "./pages/backendService/BackendServicePage";
import WordAddInPage from "./pages/wordAddIn/WordAddInPage";
import OutlookAddInPage from "./pages/outlookAddIn/OutlookAddInPage";
import TeamsAppManifestPage from "./pages/teamsApp/TeamsAppManifestPage";
import MigratioPortalPage from "./pages/MigratioPortal/MigratioPortalPage";
import LoginForm from "./pages/authentication/Login";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <Navbar />

        <Routes>
          <Route path="/backend-service" element={<BackendServicePage />} />
          <Route path="/word-addIn" element={<WordAddInPage />} />
          <Route path="/outlook-addIn" element={<OutlookAddInPage />} />
          <Route path="/Migration-Portal" element={<MigratioPortalPage />} />
          <Route path="/teams-app" element={<TeamsAppManifestPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<Cards />} />
        </Routes>
      </AppProvider>

      <DevBy />

      {/* Footer / Version */}
      <Box
        as="footer"
        textAlign="center"
        py={2}
        mt={4}
        fontWeight="bold"
        fontSize="sm"
      >
        <Text fontSize="sm" color="gray.600">
          Version : v5.0.0.0
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default App;
