/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Flex,
  Tooltip,
  FormErrorMessage,
  Switch,
} from "@chakra-ui/react";
import OutputPage from "../../components/OutputPage";
import FormHeader from "../../components/form/FormHeader";
import FormContainer from "../../components/form/FormContainer";

const MigrationPortalPage = () => {
  const [values, setValues] = useState({
    NODE_URL: "",
    SECRET_KEY: "",
    DOMAIN_URL: "",
    CLIENT_ID: "",
    CLIENT_SECRET: "",
    SITE_URL: "",
    MONGO_URI: "",
    NODE_API_KEY: "",
    CONTRACT_MIGRATION_TEMPLATE_ID: "",
    OWNER_ID: "",
    LOG_PASSWORD: "",
    CUSTOM_EXCEL_SCHEMA: false,
  });

  const [resultVisible, setResultVisible] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = JSON.stringify(values, null, 2); // Pretty print JSON output
    setResult(data);
    setResultVisible(true);
  }, [values]);

  // Ensure all text fields are not empty (toggle is not required)
  const isError =
    !values.NODE_URL.trim().length ||
    !values.SECRET_KEY.trim().length ||
    !values.DOMAIN_URL.trim().length ||
    !values.CLIENT_ID.trim().length ||
    !values.CLIENT_SECRET.trim().length ||
    !values.SITE_URL.trim().length ||
    !values.MONGO_URI.trim().length ||
    !values.NODE_API_KEY.trim().length ||
    !values.CONTRACT_MIGRATION_TEMPLATE_ID.trim().length ||
    !values.OWNER_ID.trim().length ||
    !values.LOG_PASSWORD.trim().length;

  if (resultVisible) {
    return (
      <OutputPage
        title="Migration Portal Config"
        content={result}
        fileName="migration-config.json"
      />
    );
  }

  return (
    <FormContainer>
      <FormHeader title="Migration Portal Configuration" />
      <form onSubmit={onSubmit}>
        <Stack w={["100%", "100%"]} boxSize={["sm", "lg"]}>
          {/* NODE_URL */}
          <FormControl isInvalid={!values.NODE_URL.trim().length}>
            <FormLabel htmlFor="NODE_URL">Node API URL</FormLabel>
            <Tooltip label="URL of the Node.js API service" placement="top-end">
              <Input
                id="NODE_URL"
                name="NODE_URL"
                value={values.NODE_URL}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.NODE_URL.trim().length && (
              <FormErrorMessage>Node API URL is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* SECRET_KEY */}
          <FormControl isInvalid={!values.SECRET_KEY.trim().length}>
            <FormLabel htmlFor="SECRET_KEY">Secret Key</FormLabel>
            <Tooltip label="Enter the secret key" placement="top-end">
              <Input
                id="SECRET_KEY"
                name="SECRET_KEY"
                value={values.SECRET_KEY}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.SECRET_KEY.trim().length && (
              <FormErrorMessage>Secret Key is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* DOMAIN_URL */}
          <FormControl isInvalid={!values.DOMAIN_URL.trim().length}>
            <FormLabel htmlFor="DOMAIN_URL">Domain URL</FormLabel>
            <Tooltip label="URL of the backend domain" placement="top-end">
              <Input
                id="DOMAIN_URL"
                name="DOMAIN_URL"
                value={values.DOMAIN_URL}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.DOMAIN_URL.trim().length && (
              <FormErrorMessage>Domain URL is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* CLIENT_ID */}
          <FormControl isInvalid={!values.CLIENT_ID.trim().length}>
            <FormLabel htmlFor="CLIENT_ID">Client ID</FormLabel>
            <Tooltip label="Azure App Registration Client ID" placement="top-end">
              <Input
                id="CLIENT_ID"
                name="CLIENT_ID"
                value={values.CLIENT_ID}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.CLIENT_ID.trim().length && (
              <FormErrorMessage>Client ID is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* CLIENT_SECRET */}
          <FormControl isInvalid={!values.CLIENT_SECRET.trim().length}>
            <FormLabel htmlFor="CLIENT_SECRET">Client Secret</FormLabel>
            <Tooltip label="Client Secret" placement="top-end">
              <Input
                id="CLIENT_SECRET"
                name="CLIENT_SECRET"
                value={values.CLIENT_SECRET}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.CLIENT_SECRET.trim().length && (
              <FormErrorMessage>Client Secret is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* SITE_URL */}
          <FormControl isInvalid={!values.SITE_URL.trim().length}>
            <FormLabel htmlFor="SITE_URL">Site URL</FormLabel>
            <Tooltip label="URL of the site" placement="top-end">
              <Input
                id="SITE_URL"
                name="SITE_URL"
                value={values.SITE_URL}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.SITE_URL.trim().length && (
              <FormErrorMessage>Site URL is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* MONGO_URI */}
          <FormControl isInvalid={!values.MONGO_URI.trim().length}>
            <FormLabel htmlFor="MONGO_URI">Mongo URI</FormLabel>
            <Tooltip label="MongoDB connection string" placement="top-end">
              <Input
                id="MONGO_URI"
                name="MONGO_URI"
                value={values.MONGO_URI}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.MONGO_URI.trim().length && (
              <FormErrorMessage>Mongo URI is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* NODE_API_KEY */}
          <FormControl isInvalid={!values.NODE_API_KEY.trim().length}>
            <FormLabel htmlFor="NODE_API_KEY">Node API Key</FormLabel>
            <Tooltip label="API key for Node.js service" placement="top-end">
              <Input
                id="NODE_API_KEY"
                name="NODE_API_KEY"
                value={values.NODE_API_KEY}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.NODE_API_KEY.trim().length && (
              <FormErrorMessage>Node API Key is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* CONTRACT_MIGRATION_TEMPLATE_ID */}
          <FormControl isInvalid={!values.CONTRACT_MIGRATION_TEMPLATE_ID.trim().length}>
            <FormLabel htmlFor="CONTRACT_MIGRATION_TEMPLATE_ID">Contract Migration Template ID</FormLabel>
            <Tooltip label="ID of the migration template" placement="top-end">
              <Input
                id="CONTRACT_MIGRATION_TEMPLATE_ID"
                name="CONTRACT_MIGRATION_TEMPLATE_ID"
                value={values.CONTRACT_MIGRATION_TEMPLATE_ID}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.CONTRACT_MIGRATION_TEMPLATE_ID.trim().length && (
              <FormErrorMessage>Template ID is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* OWNER_ID */}
          <FormControl isInvalid={!values.OWNER_ID.trim().length}>
            <FormLabel htmlFor="OWNER_ID">Owner ID</FormLabel>
            <Tooltip label="Owner's identifier" placement="top-end">
              <Input
                id="OWNER_ID"
                name="OWNER_ID"
                value={values.OWNER_ID}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.OWNER_ID.trim().length && (
              <FormErrorMessage>Owner ID is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* LOG_PASSWORD */}
          <FormControl isInvalid={!values.LOG_PASSWORD.trim().length}>
            <FormLabel htmlFor="LOG_PASSWORD">Log Password</FormLabel>
            <Tooltip label="Password for logging purposes" placement="top-end">
              <Input
                id="LOG_PASSWORD"
                name="LOG_PASSWORD"
                value={values.LOG_PASSWORD}
                onChange={onChange}
                autoComplete="off"
              />
            </Tooltip>
            {!values.LOG_PASSWORD.trim().length && (
              <FormErrorMessage>Log Password is required.</FormErrorMessage>
            )}
          </FormControl>

          {/* CUSTOM_EXCEL_SCHEMA toggle */}
          <FormControl display="flex" alignItems="center" mt={4}>
            <FormLabel htmlFor="CUSTOM_EXCEL_SCHEMA" mb="0">
              Custom Excel Schema
            </FormLabel>
            <Switch
              id="CUSTOM_EXCEL_SCHEMA"
              isChecked={values.CUSTOM_EXCEL_SCHEMA}
              onChange={(e) =>
                setValues((prev) => ({ ...prev, CUSTOM_EXCEL_SCHEMA: e.target.checked }))
              }
              colorScheme="facebook"
            />
          </FormControl>

          <Flex justifyContent="center" gap={5}>
            <Button mt={4} variant="outline" colorScheme="facebook" width="45%">
              Back
            </Button>
            <Button size="md" colorScheme="facebook" type="submit" mt={4} width="45%" isDisabled={isError}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormContainer>
  );
};

export default MigrationPortalPage;
